import zhCN from './lang/zh-CN/index.json';
import en from './lang/en/index.json';
import { IToolbar, IWords } from '../index';
export interface ICONFIG {
  language: {
    'zh-CN': IWords
    en: IWords
    [key: string]: IWords
  }
  langList: string[]
  toolbar: IToolbar
}

export const CONFIG: ICONFIG = {
  language: {
    'zh-CN': zhCN,
    en,
  },
  langList: ['zh-CN', 'en'],
  toolbar: {
    h1: false,
    h2: false,
    h3: false,
    h4: false,
    img: false,
    link: false,
    code: false,
    preview: false,
    expand: true,
    undo: false,
    redo: false,
    save: false,
    subfield: false,
  },
};
